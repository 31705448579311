﻿/**
 *  @ngdoc controller
 *  @name Drilldot Directive
 *  @description Drill-Dot Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('multimModel', function ($timeout) {
        return {
            controller: 'multimController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '=',
                patientname: '=',
                eyeData: '='
            },
            templateUrl: 'app/directive/multim/multimdir.html'
        }
    }).controller('multimController', function ($scope, eyeResource) {

    });

}(window.angular));